import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button } from '@mui/material';

function MysteryBox() {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedImages = location.state?.selectedImages || [];

    const downloadImage = (imageData, index) => {
        const link = document.createElement('a');
        link.href = `data:image/png;base64,${imageData}`;
        link.download = `image_${index}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGoToStart = () => {
        navigate('/start');
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Mystery Box
            </Typography>
            <Grid container spacing={2}>
                {selectedImages.map((imageData, index) => (
                    <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center" border={1} borderRadius={4} padding={2} marginBottom={2}>
                            <img
                                src={imageData}
                                alt={`selected ${index}`}
                                style={{ width: '100%', height: 'auto', maxWidth: '400px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => downloadImage(imageData.image_data, index)}
                                style={{ marginLeft: '16px' }}
                            >
                                Download
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleGoToStart}
                style={{ marginTop: '16px' }}
            >
                Go to Start
            </Button>
        </Container>
    );
}

export default MysteryBox;
