import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
    Container,
    Typography,
    CircularProgress,
    Grid,
    Box,
    Button,
    Slider,
    TextField
} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import notificationSound from '../notification.mp3';
import notificationSoundOne from '../notificationOne.mp3';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {CircularProgressWithLabel} from "../components/CustomCircularProgress";
import Header from "../components/Header";


function GenerateContent() {
    const location = useLocation();
    const {sessionIdrUl} = useParams();
    const [readOnly, setReadOnly] = useState(false);

    const facePhoto = location.state?.facePhoto || null;
    const posePhotos = location.state?.posePhotos || [];
    const countPhotos = location.state?.maxPhotos || 3;
    const faceOnly = location.state?.isEnabled || false;
    const steps = location.state?.progressSteps || 0;
    const time = location.state?.time || 0;
    const hairColor = location.state?.hairColor || 0;
    const eyeColor = location.state?.eyeColor || 0;
    const hairstyle = location.state?.hairstyle || 0;
    const age = location.state?.age || 0;
    const breastSize = location.state?.breastSize || 0;
    const sessionId = location.state?.sessionId || 0;
    const isLoraEnabled = location.state?.isLoraEnabled || false;
    const loraValue = location.state?.loraValue || null;
    const promptText = location.state?.promptText || null;
    console.log('location.state', location.state);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [results, setResults] = useState({});
    const [selectedImages, setSelectedImages] = useState({});
    const [progressMessages, setProgressMessages] = useState({});
    const hasInitiated = useRef(false);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [totalProgress, setTotalProgress] = useState(posePhotos.length * countPhotos);
    const audioRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [remainingTime, setRemainingTime] = useState(time);
    const [imageRemainingTime, setImageRemainingTime] = useState(Math.round(time / (posePhotos.length * countPhotos)));
    const [uploadProgressImage, setUploadProgressImage] = useState(0);
    const [fixHandValue, setFixHandValue] = useState(20);
    const [customFixHandValue, setCustomFixHandValue] = useState('20');
    const [sliderValues, setSliderValues] = useState({});
    const [sessionData, setSessionData] = useState(null);
    const [selectedFlow, setSelectedFlow] = useState(localStorage.getItem(
        'selectedFlow'
    ));


    console.log('results', results);

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const response = await axios.get(`${API_GENERATE_URL}/generations/${sessionIdrUl}`);
                const {generations} = response.data;
                setSessionData(generations);
                setTotalProgress(generations.length * generations[0].count_photos);
                setResults(parseGenerationData(generations));
                setLoading(false);

            } catch (error) {
                console.error('Error fetching session data:', error);
                setError(error);
                setLoading(false);
            }
        };
        if (!sessionId) {
            fetchSessionData();
            setReadOnly(true);
            console.log('readOnly', readOnly)
        }
    }, [sessionIdrUl]);

    const parseGenerationData = (generations) => {
        const result = {};
        generations.forEach(gen => {
            const {pose_image, prompt_id, images, execution_time} = gen;
            console.log('gen', gen);
            if (!result[pose_image]) {
                result[pose_image] = [];
            }
            images.forEach((image, index) => {
                if (image) {
                    result[pose_image].push({
                        promptId: `${prompt_id}_${index}`,
                        images: [image],
                        time_per_image: execution_time
                    });
                }
            });
        });
        return result;
    };


    useEffect(() => {
        if (sessionId) {
            if (!audioRef.current) {
                audioRef.current = new Audio(notificationSound);
            }

            if (!loading) {
                audioRef.current.play();
            }
        }

    }, [loading]);

    const playSoundOne = () => {
        new Audio(notificationSoundOne).play();
    };


    const API_GENERATE_URL = process.env.REACT_APP_API_URL;

    const processGeneration = useCallback(async () => {
        try {
            if (hasInitiated.current) return;
            hasInitiated.current = true;

            setUploadProgress(0);

            const result_list = [];
            setProgress(0);
            for (const pose of posePhotos) {
                console.log(pose)
                const formData = new FormData();
                formData.append('face', facePhoto);
                formData.append('pose', pose);
                formData.append('count_photos', countPhotos);
                formData.append('face_only', faceOnly);
                formData.append('session_id', sessionId);
                formData.append('hair_color', hairColor);
                formData.append('eye_color', eyeColor);
                formData.append('hairstyle', hairstyle);
                formData.append('age', age);
                formData.append('breast_size', breastSize);
                formData.append('session_id', sessionId);
                formData.append('main_prompt', promptText);
                formData.append('enabled_lora', isLoraEnabled);
                formData.append('lora_breast_size', loraValue);
                formData.append('flow', selectedFlow);

                console.log('formData', {
                    facePhoto,
                    pose,
                    countPhotos,
                    faceOnly,
                    sessionId,
                    hairColor,
                    eyeColor,
                    hairstyle,
                    age,
                    breastSize,
                    selectedFlow // И это
                });

                const response = await axios.post(`${API_GENERATE_URL}/start_polling/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                const promptData = response.data.response;
                console.log('promptData', promptData);

                setUploadProgressImage(0);

                result_list.push(...promptData);
                console.log(result_list);
                for (const {prompt_id} of promptData) {
                    console.log(prompt_id);
                    setImageRemainingTime(time / (steps / (posePhotos.length * countPhotos)));
                    console.log(imageRemainingTime);
                    setUploadProgressImage(null);
                    setProgress(prevProgress => prevProgress + 1);
                    const eventSource = new EventSource(`${API_GENERATE_URL}/track_progress/${prompt_id}/${countPhotos}`);
                    eventSource.onmessage = function (event) {
                        const message = JSON.parse(event.data);
                        if (message.msg) {
                            setUploadProgress(prevProgress => {
                                const newProgress = prevProgress + (100 / steps);
                                console.log(newProgress);
                                return newProgress;
                            });

                            setUploadProgressImage(prevProgress => {
                                const newProgress = prevProgress + (100 / (steps / (posePhotos.length * countPhotos)));
                                console.log(newProgress);
                                return newProgress;
                            });

                            setRemainingTime(prevTime => {
                                const newTime = prevTime - (time / steps);
                                return newTime > 0 ? Math.round(newTime) : 0;
                            });

                            console.log(imageRemainingTime);

                            setProgressMessages(prevMessages => ({
                                ...prevMessages,
                                [prompt_id]: message.msg
                            }));
                        }
                    };

                    await new Promise((resolve) => {
                        eventSource.onerror = (event) => {
                            if (event.target.readyState === EventSource.CLOSED) {
                                checkStatus([{prompt_id, pose}], resolve);
                            } else {
                                eventSource.close();
                                checkStatus([{prompt_id, pose}], resolve);
                            }
                        };
                    });

                    eventSource.close();
                }
            }

            setLoading(false);
        } catch (error) {
            console.error('Error during generation:', error);
            setError(error);
            setLoading(false);
        }
    }, [API_GENERATE_URL, facePhoto, posePhotos]);


    const checkStatus = useCallback(async (promptIds, resolve) => {
        try {
            const responses = await Promise.all(
                promptIds.map(({prompt_id}) => axios.get(`${API_GENERATE_URL}/check_status/${prompt_id}`))
            );

            const completedResults = responses.filter(response => response.data.status === 'completed');

            setResults(prevResults => {
                const newResults = {...prevResults};
                completedResults.forEach(result => {
                    const promptId = result.data.prompt_id;
                    const images = Array.isArray(result.data.images) ? result.data.images : [result.data.images];
                    const originalPose = result.data.pose[0];
                    const time_per_image = result.data.time_per_image;
                    console.log('originalPose', originalPose);

                    if (!newResults[originalPose]) {
                        newResults[originalPose] = [];
                    }

                    newResults[originalPose] = newResults[originalPose].filter(item => item.promptId !== promptId);

                    images.forEach((image, index) => {
                        newResults[originalPose].push({
                            promptId: `${promptId}_${index}`,
                            images: [image],
                            time_per_image
                        });
                    });
                });
                return newResults;
            });

            playSoundOne();

            if (resolve) resolve();
        } catch (error) {
            console.error('Error checking status:', error);
            setError(error);
            if (resolve) resolve();
        }
    }, [API_GENERATE_URL]);


    useEffect(() => {
        processGeneration();
    }, [processGeneration]);

    const handleImageSelect = (base64Pose, promptId, imageIndex) => {
        setSelectedImages({
            [base64Pose]: `${promptId}_${imageIndex}`
        });
    };


    const handleRegenerateAll = async () => {
        setLoading(true);
        setSelectedImages({});
        setProgressMessages({});
        setResults({});
        hasInitiated.current = false;
        await processGeneration();
    };

    const handleImageClick = (base64Pose, promptId, imageIndex, isOriginal = false) => {
        const selectedPrompt = results[base64Pose]?.find(item => item.promptId === promptId);
        if (selectedPrompt || isOriginal) {
            const images = [base64Pose, ...results[base64Pose]?.map(img => img.images[0])];
            setLightboxImages(images);
            setPhotoIndex(isOriginal ? 0 : imageIndex + 1); // +1 to account for the original image at index 0
            setIsOpen(true);
        }
    };


    const handleRegenerateSingle = async (index) => {
        setLoading(true);
        setUploadProgress(0);

        setProgress(0);
        setTotalProgress(countPhotos);
        const pose_choose = posePhotos[index]

        const formData = new FormData();
        formData.append('face', facePhoto);
        formData.append('pose', pose_choose);
        formData.append('count_photos', countPhotos);
        formData.append('face_only', faceOnly);
        formData.append('session_id', sessionId);
        formData.append('hair_color', hairColor);
        formData.append('eye_color', eyeColor);
        formData.append('hairstyle', hairstyle);
        formData.append('age', age);
        formData.append('breast_size', breastSize);
        formData.append('session_id', sessionId);
        formData.append('main_prompt', promptText);
        formData.append('enabled_lora', isLoraEnabled);
        formData.append('lora_breast_size', loraValue);


        const response = await axios.post(`${API_GENERATE_URL}/start_polling/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const promptData = response.data.response;

        for (const {prompt_id} of promptData) {
            setImageRemainingTime(time / (steps / (posePhotos.length * countPhotos)));
            setUploadProgressImage(null);
            setProgress(prevProgress => prevProgress + 1);
            const eventSource = new EventSource(`${API_GENERATE_URL}/track_progress/${prompt_id}/${countPhotos}`);
            setUploadProgressImage(0)
            eventSource.onmessage = function (event) {
                const message = JSON.parse(event.data);
                if (message.msg) {
                    setUploadProgress(prevProgress => {
                        const newProgress = prevProgress + (100 / steps);
                        console.log(newProgress);
                        return newProgress;
                    });

                    setUploadProgressImage(prevProgress => {
                        const newProgress = prevProgress + (100 / (steps / (posePhotos.length * countPhotos)));
                        console.log(newProgress);
                        return newProgress;
                    });

                    setRemainingTime(prevTime => {
                        const newTime = prevTime - (time / steps);
                        return newTime > 0 ? Math.round(newTime) : 0;
                    });

                    console.log(imageRemainingTime)

                    setProgressMessages(prevMessages => ({
                        ...prevMessages,
                        [prompt_id]: message.msg
                    }));
                }
            };

            await new Promise((resolve) => {
                eventSource.onerror = (event) => {
                    if (event.target.readyState === EventSource.CLOSED) {
                        checkStatus([{prompt_id, pose_choose}], resolve);
                    } else {
                        eventSource.close();
                        checkStatus([{prompt_id, pose_choose}], resolve);
                    }
                };
            });

            eventSource.close();
        }
        setLoading(false);
    };


    const handleCreateMysteryBox = () => {
        const selectedImagesData = Object.keys(selectedImages).map(base64Pose => {
            const promptId = selectedImages[base64Pose];
            const selectedPrompt = results[base64Pose].find(item => item.promptId === promptId);
            return selectedPrompt ? selectedPrompt.images[0] : null;
        }).filter(image => image !== null);

        navigate('/mystery-box', {state: {selectedImages: selectedImagesData}});
    };

    const handleFixHand = async (promptId, image, imageIndex) => {
        try {
            setLoading(true);
            const uniqueId = `${promptId}_${imageIndex}`;
            const valueToSend = sliderValues[uniqueId] ? sliderValues[uniqueId] : fixHandValue;
            console.log('Fix Hand Value:', valueToSend, 'Prompt ID:', promptId, 'Image:', image);

            const startResponse = await axios.post(`${API_GENERATE_URL}/fix_hand/`, {
                prompt_id: promptId,
                value: valueToSend,
                image: image,
                session_id: sessionId,
            });

            const {prompt_id: newPromptId} = startResponse.data;
            setUploadProgress(null);
            setImageRemainingTime(200 / (600));
            setUploadProgressImage(null);
            setProgress(1);

            const eventSource = new EventSource(`${API_GENERATE_URL}/track_progress/${newPromptId}/3`);
            setUploadProgressImage(0);

            eventSource.onmessage = function (event) {
                const message = JSON.parse(event.data);
                if (message.msg) {
                    setUploadProgress(prevProgress => {
                        const newProgress = prevProgress + (100 / 600);
                        console.log('Upload Progress:', newProgress);
                        return newProgress;
                    });

                    setUploadProgressImage(prevProgress => {
                        const newProgress = prevProgress + (100 / (600));
                        console.log('Upload Progress Image:', newProgress);
                        return newProgress;
                    });

                    setRemainingTime(prevTime => {
                        const newTime = prevTime - (200 / 600);
                        return newTime > 0 ? Math.round(newTime) : 0;
                    });

                    setProgressMessages(prevMessages => ({
                        ...prevMessages,
                        [newPromptId]: message.msg
                    }));
                }
            };

            await new Promise((resolve) => {
                eventSource.onerror = (event) => {
                    if (event.target.readyState === EventSource.CLOSED) {
                        eventSource.close();
                        resolve();
                    } else {
                        eventSource.close();
                        resolve();
                    }
                };
            });

            eventSource.close();

            const resultsResponse = await axios.get(`${API_GENERATE_URL}/check_status/${newPromptId}`);
            const {images, time_per_image, pose} = resultsResponse.data;
            console.log('New Images:', images);

            setResults(prevResults => {
                const newResults = {...prevResults};
                const base64Pose = pose[0]; // Assume pose is an array and we need the first element
                if (!newResults[base64Pose]) {
                    newResults[base64Pose] = [];
                }
                // Find the index of the original image and insert new images after it
                const originalIndex = newResults[base64Pose].findIndex(item => item.promptId.startsWith(promptId));
                const newItems = images.map((img, idx) => ({
                    promptId: `${newPromptId}_${idx}`,
                    images: [img],
                    time_per_image: time_per_image
                }));
                newResults[base64Pose].splice(originalIndex + 1, 0, ...newItems);
                return newResults;
            });

            setLoading(false);
            // Clear other selected images
            setSelectedImages({});
        } catch (error) {
            console.error('Error fixing hand:', error);
            setLoading(false);
        }
    };


    const handleSliderChange = (promptId, index, value) => {
        const uniqueId = `${promptId}_${index}`;
        setSliderValues(prevSliderValues => ({
            ...prevSliderValues,
            [uniqueId]: value
        }));
    };

    function formatExecutionTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = (timeInSeconds % 60).toFixed(2);

        if (minutes > 0) {
            return `Execution Time: ${minutes} minutes ${seconds} seconds`;
        } else {
            return `Execution Time: ${seconds} seconds`;
        }
    }


    const handleStopGeneration = async () => {
        try {
            const response = await axios.post(`${API_GENERATE_URL}/trigger_interrupt`);
            if (response.status === 200) {
                console.log('Interrupt request sent successfully');
            } else {
                console.log('Failed to send interrupt request');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const isGenerateButtonDisabled = Object.keys(results).length === 0 || Object.keys(selectedImages).length !== Object.keys(results).length;

    return (
        <Container style={{width: '100%', maxWidth: '100%'}}>
            <Header/>
            <Typography variant="h4" gutterBottom>
                Generated Photos
            </Typography>
            <Grid container spacing={2} style={{marginTop: '16px'}}>
                {Object.keys(results).map((poseUrl, index) => (
                    <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center" border={1} borderRadius={4} padding={2}
                             marginBottom={2}>
                            <Box>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h6">Original image</Typography>
                                            </Grid>
                                            <Grid item>
                                                <img
                                                    src={poseUrl}
                                                    alt={`original ${index}`}
                                                    style={{width: '100%', cursor: 'pointer'}}
                                                    onClick={() => handleImageClick(poseUrl, null, 0, true)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {Array.isArray(results[poseUrl]) && results[poseUrl].map(({
                                                                                                  promptId,
                                                                                                  images,
                                                                                                  time_per_image
                                                                                              }, outerIndex) => (
                                        Array.isArray(images) && images.map((imageData, imageIndex) => (
                                            <Grid item xs={12} sm={6} md={3} key={imageIndex}>
                                                <Grid container direction="column" alignItems="center">
                                                    <Grid item>
                                                        <Typography
                                                            variant="h6">{`Generated Images ${outerIndex + 1}`}</Typography>
                                                    </Grid>
                                                    <Grid item style={{position: 'relative'}}>
                                                        <img
                                                            src={imageData}
                                                            alt={`generated ${imageIndex}`}
                                                            style={{
                                                                width: '100%',
                                                                border: selectedImages[poseUrl] === `${promptId}_${imageIndex}` ? '2px solid blue' : 'none',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleImageClick(poseUrl, promptId, outerIndex)}
                                                        />
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                left: '10px',
                                                                width: '50px',
                                                                height: '50px',
                                                                borderRadius: '50%',
                                                                cursor: 'pointer',
                                                                background: 'white'
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleImageSelect(poseUrl, promptId, imageIndex);
                                                            }}
                                                        />
                                                        {selectedImages[poseUrl] === `${promptId}_${imageIndex}` && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '10px',
                                                                    left: '10px',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    borderRadius: '50%',
                                                                    background: 'blue',
                                                                    border: '2px solid white'
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleImageSelect(poseUrl, promptId, imageIndex);
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{formatExecutionTime(time_per_image)}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleFixHand(promptId, imageData, imageIndex)}
                                                            style={{marginTop: '10px'}}
                                                            disabled={loading || readOnly}
                                                        >
                                                            Fix Hand
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Slider
                                                            value={sliderValues[`${promptId}_${imageIndex}`] || fixHandValue}
                                                            min={20}
                                                            max={60}
                                                            onChange={(e, value) => handleSliderChange(promptId, imageIndex, value)}
                                                            aria-labelledby="continuous-slider"
                                                            style={{width: '200px', marginTop: '10px'}}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            label="Custom Fix Hand Value"
                                                            type="number"
                                                            value={sliderValues[`${promptId}_${imageIndex}`] || customFixHandValue}
                                                            onChange={(e) => handleSliderChange(promptId, imageIndex, parseInt(e.target.value))}
                                                            style={{width: '200px', marginTop: '10px'}}
                                                            InputProps={{inputProps: {min: 20, max: 60}}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))
                                    ))}
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={loading || readOnly}
                                    onClick={() => handleRegenerateSingle(index)}
                                    style={{marginLeft: '20px', marginRight: '10px'}} // Добавлен отступ сверху
                                >
                                    Regenerate
                                </Button>
                            </Box>

                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Button
                variant="contained"
                color="primary"
                disabled={isGenerateButtonDisabled || loading || readOnly}
                style={{marginBottom: '16px'}}
                onClick={handleCreateMysteryBox}
            >
                Generate Mystery Box
            </Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={loading || readOnly}
                onClick={handleRegenerateAll}
                style={{marginBottom: '16px', marginLeft: '16px'}}
            >
                {`Regenerate All (~${time / 60} min)`}
            </Button>
            {loading && (
                <Container style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'fixed',
                    top: '10px',
                    right: '10px',
                    width: '300px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    padding: '16px',
                    paddingTop: '40px',
                    textAlign: 'center'
                }}>
                    <Typography variant="h6" gutterBottom>
                        Processing...
                    </Typography>
                    <CircularProgress/>
                    <Typography variant="body1" gutterBottom>
                        {`Time remaining: ${remainingTime} seconds`}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Full Progress
                    </Typography>
                    <CircularProgressWithLabel value={uploadProgress}/>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleStopGeneration}
                        style={{marginTop: '16px', backgroundColor: 'red', color: 'white'}}
                    >
                        Stop Generation
                    </Button>
                    <Box mt={2} style={{
                        width: '100%',
                        overflowY: 'auto',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography variant="body1" gutterBottom>
                            Image Progress
                        </Typography>
                        <CircularProgressWithLabel value={uploadProgressImage}/>
                        {`Progress: ${progress} / ${totalProgress}`}
                        {Object.values(progressMessages).map((msg, index) => (
                            <Typography key={index} variant="body2">
                                {msg}
                            </Typography>
                        ))}
                    </Box>
                </Container>
            )}

            {isOpen && lightboxImages.length > 0 && (
                <Lightbox
                    mainSrc={lightboxImages[photoIndex]}
                    nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
                    prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + lightboxImages.length - 1) % lightboxImages.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImages.length)}
                />
            )}
        </Container>
    );
}

export default GenerateContent;
