import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Button,
    Grid,
    IconButton,
    TextField,
    FormControlLabel,
    Switch,
    Dialog, DialogTitle, DialogContent, DialogActions, AppBar, Toolbar, Link, CircularProgress
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import Header from "../components/Header";

function ChoosePhoto() {
    const [facePhoto, setFacePhoto] = useState(null);
    const [posePhotos, setPosePhotos] = useState([]);
    const [maxPhotos, setMaxPhotos] = useState(3);
    const [isEnabled, setIsEnabled] = useState(false);
    const [time, setTime] = useState(null);
    const [progressSteps, setProgressSteps] = useState(null);
    const [models, setModels] = useState([]);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hairColor, setHairColor] = useState('');
    const [eyeColor, setEyeColor] = useState('');
    const [hairstyle, setHairstyle] = useState('');
    const [age, setAge] = useState('');
    const [breastSize, setBreastSize] = useState('');
    const [loraValue, setLoraValue] = useState('');
    const [promptText, setPromptText] = useState('');
    const [isLoraEnabled, setIsLoraEnabled] = useState(true);

    const handleLoraSwitchChange = (event) => {
        setIsLoraEnabled(event.target.checked);
    };

    const handleLoraValueChange = (event) => {
        setLoraValue(event.target.value);
    };

    const handlePromptTextChange = (event) => {
        setPromptText(event.target.value);
    };


    const API_GENERATE_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (isEnabled) {
            setTime(maxPhotos * 30 * posePhotos.length);
            setProgressSteps(maxPhotos * 13 * posePhotos.length)
            console.log(posePhotos.length, maxPhotos)
            console.log(progressSteps)
        } else {
            setTime(maxPhotos * 90 * posePhotos.length);
            setProgressSteps(maxPhotos * 35 * posePhotos.length)
            console.log(posePhotos.length, maxPhotos)
            console.log(progressSteps)
        }

        async function fetchModels() {
            try {
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
            } catch (error) {
                console.error('Error fetching models:', error);
            }
        }

        fetchModels();
    }, [isEnabled, maxPhotos, posePhotos, facePhoto])

    const handlePoseUpload = (event) => {
        const files = event.target.files;
        const newPhotos = Array.from(files);
        setPosePhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    };

    const handlePhotoDelete = (index, type) => {
        if (type === 'face') {
            setFacePhoto(null);
        } else if (type === 'pose') {
            setPosePhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
        }
    };

    const handleStartGenerating = () => {
        if (facePhoto && posePhotos.length > 0 && maxPhotos > 0) {
            const generatedSessionId = uuidv4();
            console.log('generatedSessionId', generatedSessionId);

            navigate(`/generate/${generatedSessionId}`, {
                state: {
                    facePhoto,
                    posePhotos,
                    maxPhotos,
                    isEnabled,
                    time,
                    progressSteps,
                    hairColor,
                    eyeColor,
                    hairstyle,
                    age,
                    breastSize,
                    sessionId: generatedSessionId,
                    isLoraEnabled,
                    loraValue,
                    promptText,
                }
            });
        } else {
            alert('Please upload at least one photo for both Face and Pose and count photos.');
        }
    };

    const handleMaxPhotosChange = (event) => {
        const value = event.target.value;
        if (value === '') {
            setMaxPhotos('');
        } else if (parseInt(value) >= 1) {
            setMaxPhotos(parseInt(value));
        }
    };

    const handleSwitchChange = (event) => {
        setIsEnabled(event.target.checked);
    };

    const handleSelectModel = (model) => {
        setSelectedModel(model);
        setSelectedPhoto(null); // Сброс выбранной фотографии при выборе новой модели
        setHairColor(model.hair_color || '');
        setEyeColor(model.eye_color || '');
        setHairstyle(model.hairstyle || '');
        setAge(model.age || '');
        setBreastSize(model.breast_size || '');
    };


    const handleSelectPhoto = (photo) => {
        setSelectedPhoto(photo);
        setFacePhoto(photo);
    };

    const handleBackToModels = () => {
        setSelectedModel(null);
        setSelectedPhoto(null);
    };

    const handleHairColorChange = (event) => {
        setHairColor(event.target.value);
    };

    const handleEyeColorChange = (event) => {
        setEyeColor(event.target.value);
    };

    const handleHairstyleChange = (event) => {
        setHairstyle(event.target.value);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleBreastSizeChange = (event) => {
        setBreastSize(event.target.value);
    };


    return (
        <Container>
            <Header/>
            <Typography variant="h4" gutterBottom>
                Choose photos for your Mystery Box
            </Typography>

            <Grid container spacing={2} marginTop={5}>
                <FormControlLabel
                    control={<Switch checked={isEnabled} onChange={handleSwitchChange}/>}
                    label="Only Face"
                />
            </Grid>

            {!isEnabled && (
                <>
                    <Grid container spacing={2} marginTop={2} alignItems={'center'}>
                        <FormControlLabel
                            control={<Switch checked={isLoraEnabled} onChange={handleLoraSwitchChange}/>}
                            label="Enable Lora Breasts"
                        />
                    </Grid>

                    {isLoraEnabled && (
                        <Grid container spacing={2} marginTop={2} alignItems={'center'}>
                            <TextField
                                label="Lora breasts"
                                type="number"
                                value={loraValue}
                                onChange={handleLoraValueChange}
                                style={{marginBottom: '20px'}}
                            />
                        </Grid>
                    )}
                    <Grid container spacing={2} marginTop={2} alignItems={'center'}>
                        <TextField
                            label="Prompt"
                            type="text"
                            multiline
                            fullWidth
                            rows={4}
                            value={promptText}
                            onChange={handlePromptTextChange}
                            style={{marginBottom: '20px'}}
                        />
                    </Grid>
                </>
            )}

            <Grid container spacing={2} marginTop={2} alignItems={'center'}>
                <TextField
                    label="Count Pose Photos"
                    type="number"
                    value={maxPhotos}
                    onChange={handleMaxPhotosChange}
                    inputProps={{min: 1}}
                    style={{marginBottom: '20px'}}
                />
            </Grid>


            <Typography variant="h4" gutterBottom>
                Choose model
            </Typography>
            <Grid container spacing={2} marginTop={2} alignItems="center">
                {models.map((model, index) => (
                    <Grid item key={index} onClick={() => handleSelectModel(model)}
                          style={{position: 'relative', cursor: 'pointer', width: '200px'}}>
                        <div style={{textAlign: 'center'}}>
                            <img
                                src={model.photos[0]}
                                style={{
                                    width: '100%',
                                    height: '200px',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    marginBottom: '8px',
                                    border: selectedModel && selectedModel.name === model.name ? '2px solid black' : 'none'
                                }}
                            />
                            <Typography variant="body2">{model.name}</Typography>
                        </div>
                    </Grid>
                ))}
                {/*<Grid item>*/}
                {/*    <div style={{textAlign: 'center', marginBottom: 15}}>*/}
                {/*        <IconButton color="primary" component="span" onClick={handleOpenModal}>*/}
                {/*            <AddCircleIcon style={{fontSize: 40}}/>*/}
                {/*        </IconButton>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            </Grid>

            {selectedModel && (
                <div style={{marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '20px'}}>
                    <Typography variant="h6" gutterBottom>
                        Select photo of {selectedModel.name}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                            <Grid container spacing={2} style={{width: '100%'}}>
                                {selectedModel.photos.map((photo, index) => (
                                    <Grid item
                                          key={index}
                                          xs={index < 2 ? 6 : 12}
                                          sm={index < 2 ? 6 : 12}
                                          md={index < 2 ? 6 : 12}
                                          style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: index < 2 ? '50%' : '50%'
                                          }}
                                          onClick={() => handleSelectPhoto(photo)}>
                                        <div style={{textAlign: 'center', width: '200px'}}>
                                            <img
                                                src={photo}
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    border: selectedPhoto && selectedPhoto === photo ? '2px solid black' : 'none'
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>


                        <Grid item xs={6}>
                            {selectedPhoto && (
                                <div style={{display: 'flex', alignItems: 'start'}}>
                                    <div style={{marginRight: '20px'}}>
                                        <img
                                            src={selectedPhoto}
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Hair Color"
                                            fullWidth
                                            value={hairColor}
                                            onChange={handleHairColorChange}
                                            style={{marginBottom: '20px'}}
                                        />
                                        <TextField
                                            label="Eye Color"
                                            fullWidth
                                            value={eyeColor}
                                            onChange={handleEyeColorChange}
                                            style={{marginBottom: '20px'}}
                                        />
                                        <TextField
                                            label="Hairstyle"
                                            fullWidth
                                            value={hairstyle}
                                            onChange={handleHairstyleChange}
                                            style={{marginBottom: '20px'}}
                                        />
                                        <TextField
                                            label="Age"
                                            fullWidth
                                            type="number"
                                            value={age}
                                            onChange={handleAgeChange}
                                            style={{marginBottom: '20px'}}
                                        />
                                        <TextField
                                            label="Breast Size"
                                            fullWidth
                                            value={breastSize}
                                            onChange={handleBreastSizeChange}
                                            style={{marginBottom: '20px'}}
                                        />
                                    </div>
                                </div>
                            )}
                        </Grid>

                    </Grid>
                </div>
            )}


            {/*<Dialog open={isModalOpen} onClose={handleCloseModal}>*/}
            {/*    <DialogTitle>Add New Model</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <TextField*/}
            {/*            label="Model Name"*/}
            {/*            fullWidth*/}
            {/*            // value={newModelName}*/}
            {/*            onChange={handleNewModelNameChange}*/}
            {/*            style={{marginBottom: '20px', marginTop: '20px'}}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            label="Hair Color"*/}
            {/*            fullWidth*/}
            {/*            // value={hairColor}*/}
            {/*            onChange={handleHairColorChange}*/}
            {/*            style={{marginBottom: '20px'}}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            label="Eye Color"*/}
            {/*            fullWidth*/}
            {/*            // value={eyeColor}*/}
            {/*            onChange={handleEyeColorChange}*/}
            {/*            style={{marginBottom: '20px'}}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            label="Hairstyle"*/}
            {/*            fullWidth*/}
            {/*            // value={hairstyle}*/}
            {/*            onChange={handleHairstyleChange}*/}
            {/*            style={{marginBottom: '20px'}}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            label="Age"*/}
            {/*            fullWidth*/}
            {/*            type="number"*/}
            {/*            // value={age}*/}
            {/*            onChange={handleAgeChange}*/}
            {/*            style={{marginBottom: '20px'}}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            label="Breast Size"*/}
            {/*            fullWidth*/}
            {/*            // value={breastSize}*/}
            {/*            onChange={handleBreastSizeChange}*/}
            {/*            style={{marginBottom: '20px'}}*/}
            {/*        />*/}
            {/*        <input*/}
            {/*            accept="image/*"*/}
            {/*            style={{display: 'none'}}*/}
            {/*            id="upload-new-model"*/}
            {/*            type="file"*/}
            {/*            multiple*/}
            {/*            onChange={handleModelFileChange}*/}
            {/*        />*/}
            {/*        <label htmlFor="upload-new-model">*/}
            {/*            <Button*/}
            {/*                variant="contained"*/}
            {/*                color="primary"*/}
            {/*                component="span"*/}
            {/*                startIcon={<PhotoCamera/>}*/}
            {/*                disabled={isLoading}*/}
            {/*            >*/}
            {/*                Upload Model Photos*/}
            {/*            </Button>*/}
            {/*        </label>*/}
            {/*        <Grid container spacing={2} marginTop={2}>*/}
            {/*            {newModelFiles.map((file, index) => (*/}
            {/*                <Grid item xs={3} key={index}>*/}
            {/*                    <div style={{position: 'relative'}}>*/}
            {/*                        <img*/}
            {/*                            src={URL.createObjectURL(file)}*/}
            {/*                            alt={`uploaded model ${index}`}*/}
            {/*                            style={{width: '100%'}}*/}
            {/*                        />*/}
            {/*                        <IconButton*/}
            {/*                            aria-label="delete"*/}
            {/*                            style={{*/}
            {/*                                position: 'absolute',*/}
            {/*                                top: '8px',*/}
            {/*                                right: '8px',*/}
            {/*                                backgroundColor: 'rgba(255, 255, 255, 0.7)',*/}
            {/*                            }}*/}
            {/*                            onClick={() => handleDeleteModelFile(index)}*/}
            {/*                        >*/}
            {/*                            <DeleteIcon/>*/}
            {/*                        </IconButton>*/}
            {/*                    </div>*/}
            {/*                </Grid>*/}
            {/*            ))}*/}
            {/*        </Grid>*/}
            {/*        {isLoading && (*/}
            {/*            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>*/}
            {/*                <CircularProgress/>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleCloseModal} color="secondary" disabled={isLoading}>*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*        <Button onClick={handleAddModel} color="primary" disabled={isLoading}>*/}
            {/*            Add Model*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}

            <input
                accept="image/*"
                style={{display: 'none'}}
                id="upload-pose"
                multiple
                type="file"
                onChange={handlePoseUpload}
            />
            <label htmlFor="upload-pose">
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<PhotoCamera/>}
                    style={{marginTop: '36px'}}
                >
                    Upload Pose Photos
                </Button>
            </label>

            <Grid container spacing={2} marginTop={5}>
                {posePhotos.map((photo, index) => (
                    <Grid item xs={3} key={index}>
                        <div style={{position: 'relative'}}>
                            <img
                                src={URL.createObjectURL(photo)}
                                alt={`uploaded pose ${index}`}
                                style={{
                                    width: '100%',
                                    borderRadius: '8px'
                                }}
                            />
                            <IconButton
                                aria-label="delete"
                                style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                }}
                                onClick={() => handlePhotoDelete(index, 'pose')}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                ))}
            </Grid>

            {(facePhoto && posePhotos.length > 0) && (
                <Button
                    variant="contained"
                    color="secondary"
                    style={{marginTop: '16px', marginBottom: '16px'}}
                    onClick={handleStartGenerating}
                >
                    {`Start generating (~${time / 60} min)`}
                </Button>
            )}
        </Container>
    );
}

export default ChoosePhoto;
