import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';

function Header() {
    const navigate = useNavigate();
    const [selectedFlow, setSelectedFlow] = useState('');

    useEffect(() => {
        const savedFlow = localStorage.getItem('selectedFlow');
        if (savedFlow) {
            setSelectedFlow(savedFlow);
        } else {
            fetchAndSetDefaultFlow();
        }
    }, []);

    const fetchAndSetDefaultFlow = async () => {
        try {
            const API_GENERATE_URL = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${API_GENERATE_URL}/flows/`);
            const files = response.data.files;
            if (files.length > 0) {
                localStorage.setItem('selectedFlow', files[0]);
                setSelectedFlow(files[0]);
            }
        } catch (error) {
            console.error('Error fetching flows:', error);
        }
    };

    return (
        <AppBar position="static" style={{ background: '#3f51b5' }}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        style={{ backgroundColor: '#ff5722', color: '#fff', marginRight: '10px' }}
                        onClick={() => navigate('/start')}
                    >
                        Home
                    </Button>
                    <Button
                        style={{ backgroundColor: '#4caf50', color: '#fff', marginRight: '10px' }}
                        onClick={() => navigate('/history')}
                    >
                        History
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2196f3', color: '#fff' }}
                        onClick={() => navigate('/models')}
                    >
                        Models
                    </Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ color: '#fff', marginRight: '10px' }}>
                        {selectedFlow}
                    </Typography>
                    <IconButton
                        style={{ color: '#fff' }}
                        onClick={() => navigate('/settings')}
                    >
                        <SettingsIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
