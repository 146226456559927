import React, {useEffect, useState} from 'react';
import {Container, Typography, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useNavigate} from "react-router-dom";
import Header from "../components/Header";

function History() {
    const [sessions, setSessions] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);

    const navigate = useNavigate();

    const API_GENERATE_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSessions = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`${API_GENERATE_URL}/sessions/`, {
                    params: {page},
                });
                setSessions(response.data.sessions);
            } catch (err) {
                setError('Failed to load sessions');
            } finally {
                setLoading(false);
            }
        };

        fetchSessions();
    }, [page]);

    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handleAccordionChange = (sessionId) => async (event, isExpanded) => {
        setExpanded(isExpanded ? sessionId : false);

        if (isExpanded) {
            try {
                const response = await axios.get(`${API_GENERATE_URL}/generations/${sessionId}`);
                const updatedSessions = sessions.map((session) =>
                    session.session_id === sessionId ? {...session, details: response.data.generations} : session
                );
                setSessions(updatedSessions);
            } catch (err) {
                console.error('Failed to load generation details', err);
            }
        }
    };

    const handleImageClick = (images, index) => {
        setCurrentImages(images);
        setCurrentImageIndex(index);
        setLightboxOpen(true);
    };

    const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? currentImages.length - 1 : prevIndex - 1));
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
    };

    const handleGoToStart = () => {
        navigate('/start');
    };

    function formatExecutionTime(timeInSeconds) {
        console.log('timeInSeconds', timeInSeconds)
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = (timeInSeconds % 60).toFixed(2);

        if (minutes > 0) {
            return `${minutes} minutes ${seconds} seconds`;
        } else {
            return `${seconds} seconds`;
        }
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                console.log('Text copied to clipboard');
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Could not copy text: ', err);
            }
            document.body.removeChild(textArea);
        }
    };

    const copyLink = (sessionId) => {
        const link = `${window.location.origin}/generate/${sessionId}`;
        copyToClipboard(link);
    };


    return (
        <Container>
            <Header/>
            <Typography variant="h4" gutterBottom>
                Generation Sessions
            </Typography>
            {loading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && !error && (
                <Grid container spacing={2}>
                    {sessions.map((session) => {
                        const totalExecutionTime = session.details
                            ? session.details.reduce((sum, generation) => sum + generation.execution_time, 0)
                            : 0;
                        return (
                            <Grid item xs={12} key={session.session_id}>
                                <Accordion
                                    expanded={expanded === session.session_id}
                                    onChange={handleAccordionChange(session.session_id)}
                                    aria-controls="panel1-content"
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{border: '1px solid'}}
                                    >
                                        <Typography>{`Session ID: ${session.session_id} - Date: ${new Date(session.start_time).toLocaleString()}`}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {session.details ? (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontWeight: 'bold'}}>General
                                                        Information</Typography>
                                                    <Box display="flex" alignItems="left">
                                                        <Box flexGrow={1} textAlign="left">
                                                            <Typography>{`Count Photos: ${session.details[session.details.length - 1].count_photos}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Hair Color: ${session.details[session.details.length - 1].generation_data.hair_color}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Eye Color: ${session.details[session.details.length - 1].generation_data.eye_color}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Hairstyle: ${session.details[session.details.length - 1].generation_data.hairstyle}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Age: ${session.details[session.details.length - 1].generation_data.age}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Breast Size: ${session.details[session.details.length - 1].generation_data.breast_size}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Main Prompt: ${session.details[session.details.length - 1].generation_data.main_prompt}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`Enabled LoRA: ${session.details[session.details.length - 1].generation_data.enabled_lora}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#3f51b5'
                                                            }}>{`LoRA Breast Size: ${session.details[session.details.length - 1].generation_data.lora_breast_size}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#000000'
                                                            }}>{`Start Time: ${new Date(session.details[session.details.length - 1].start_time).toLocaleString()}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#000000'
                                                            }}>{`End Time: ${new Date(session.details[session.details.length - 1].end_time).toLocaleString()}`}</Typography>
                                                            <Typography style={{
                                                                fontWeight: 'bold',
                                                                color: '#000000'
                                                            }}>{`Total Execution Time: ${formatExecutionTime(totalExecutionTime)}`}</Typography>
                                                            <Typography>{`Server Info: ${session.details[session.details.length - 1].server_info}`}</Typography>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => copyLink(session.session_id)}
                                                                style={{marginLeft: '20px'}}>
                                                                Copy Link
                                                            </Button>
                                                        </Box>
                                                        {session.details.length > 0 && session.details[session.details.length - 1].face_image && (
                                                            <Box marginLeft={2}>
                                                                <img
                                                                    src={session.details[session.details.length - 1].face_image}
                                                                    alt="Face" width="300" style={{cursor: 'pointer'}}
                                                                    onClick={() => handleImageClick(session.details[session.details.length - 1].face_image)}/>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Grid>

                                                {session.details.reduce((acc, generation) => {
                                                    const poseGroup = acc.find(group => group.pose_image === generation.pose_image);
                                                    if (poseGroup) {
                                                        poseGroup.generations.push(generation);
                                                    } else {
                                                        acc.push({
                                                            pose_image: generation.pose_image,
                                                            generations: [generation]
                                                        });
                                                    }
                                                    return acc;
                                                }, []).map((group, groupIndex) => (
                                                    <Grid item xs={12} key={groupIndex}>
                                                        <Box
                                                            display="grid"
                                                            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                                                            gap={2}
                                                            alignItems="center"
                                                        >
                                                            <Box display="flex" flexDirection="column"
                                                                 alignItems="center" marginRight={2}>
                                                                <img src={group.pose_image} alt="Pose" width="200"
                                                                     style={{marginRight: '16px', cursor: 'pointer'}}
                                                                     onClick={() => handleImageClick([group.pose_image, ...group.generations.flatMap(g => g.images)], 0)}/>
                                                                <Typography style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#000000'
                                                                }}>Original image</Typography>
                                                            </Box>
                                                            {group.generations.map((generation) => (
                                                                <React.Fragment key={generation.id}>
                                                                    {generation?.images?.map((image, index) => (
                                                                        <Box key={index} display="flex"
                                                                             flexDirection="column" alignItems="center"
                                                                             marginRight={2}>
                                                                            <img src={image} alt={`Generated ${index}`}
                                                                                 width="200" style={{cursor: 'pointer'}}
                                                                                 onClick={() => handleImageClick([group.pose_image, ...group.generations.flatMap(g => g.images)], index + 1)}/>
                                                                            <Typography style={{
                                                                                fontWeight: 'bold',
                                                                                color: '#000000'
                                                                            }}>{`Execution Time: ${formatExecutionTime(generation.execution_time / generation.images.length)}`}</Typography>
                                                                        </Box>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))}
                                                        </Box>
                                                    </Grid>
                                                ))}


                                            </Grid>
                                        ) : (
                                            <Typography>Loading...</Typography>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            <Grid container spacing={2} justifyContent="space-between" marginTop={2}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handlePrevPage} disabled={page === 1}>
                        Previous
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleNextPage}>
                        Next
                    </Button>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleGoToStart}
                style={{marginTop: '16px'}}
            >
                Go to Start
            </Button>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={currentImages[currentImageIndex]}
                    nextSrc={currentImages[(currentImageIndex + 1) % currentImages.length]}
                    prevSrc={currentImages[(currentImageIndex + currentImages.length - 1) % currentImages.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </Container>
    );
}

export default History;
