import React, {useEffect, useState} from 'react';
import {
    Container,
    Typography,
    Button,
    Grid,
    IconButton,
    TextField,
    Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Lightbox from "react-image-lightbox";
import Header from "../components/Header";

function Models() {
    const [models, setModels] = useState([]);
    const [newModelName, setNewModelName] = useState('');
    const [newModelFiles, setNewModelFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hairColor, setHairColor] = useState('');
    const [eyeColor, setEyeColor] = useState('');
    const [hairstyle, setHairstyle] = useState('');
    const [age, setAge] = useState('');
    const [breastSize, setBreastSize] = useState('');
    const [selectedModel, setSelectedModel] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const API_GENERATE_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchModels() {
            try {
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
            } catch (error) {
                console.error('Error fetching models:', error);
            }
        }

        fetchModels();
    }, []);


    const handleAddModel = async () => {
        if (newModelName && newModelFiles.length > 0) {
            const formData = new FormData();
            formData.append('name', newModelName);
            newModelFiles.forEach(file => formData.append('photos', file));
            formData.append('hair_color', hairColor || '');
            formData.append('eye_color', eyeColor || '');
            formData.append('hairstyle', hairstyle || '');
            formData.append('age', age || '');
            formData.append('breast_size', breastSize || '');

            try {
                setIsLoading(true);
                await axios.post(`${API_GENERATE_URL}/add_model/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
                setNewModelName('');
                setNewModelFiles([]);
                setHairColor('');
                setEyeColor('');
                setHairstyle('');
                setAge('');
                setBreastSize('');
                setIsModalOpen(false);
            } catch (error) {
                console.error('Error adding model:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            alert('Please enter a name and select at least one photo for the new model.');
        }
    };

    const handleModelFileChange = (event) => {
        const files = Array.from(event.target.files);
        setNewModelFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleNewModelNameChange = (event) => {
        setNewModelName(event.target.value);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewModelName('');
        setNewModelFiles([]);
    };

    const handleDeleteModelFile = (index) => {
        setNewModelFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleModelSelect = (model) => {
        setSelectedModel(model);
        setHairColor(model.hair_color || '');
        setEyeColor(model.eye_color || '');
        setHairstyle(model.hairstyle || '');
        setAge(model.age || '');
        setBreastSize(model.breast_size || '');
    };

    const handleUpdateModel = async () => {
        if (selectedModel) {
            const formData = new FormData();
            formData.append('name', selectedModel.name || '');
            formData.append('hair_color', hairColor || '');
            formData.append('eye_color', eyeColor || '');
            formData.append('hairstyle', hairstyle || '');
            formData.append('age', age || '');
            formData.append('breast_size', breastSize || '');

            if (newModelFiles.length > 0) {
                newModelFiles.forEach(file => formData.append('new_photos', file));
            }

            try {
                setIsLoading(true);
                await axios.put(`${API_GENERATE_URL}/update_model/${selectedModel.id}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
                setSelectedModel(null);
                setNewModelFiles([]);
            } catch (error) {
                console.error('Error updating model:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDeleteModelPhoto = async (photoIndex) => {
        if (selectedModel) {
            try {
                setIsLoading(true);
                await axios.delete(`${API_GENERATE_URL}/models/${selectedModel.id}/photos/${photoIndex}`);
                const updatedPhotos = selectedModel.photos.filter((_, index) => index !== photoIndex);
                setSelectedModel({...selectedModel, photos: updatedPhotos}); // Обновляем состояние выбранной модели
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
            } catch (error) {
                console.error('Error deleting model photo:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleUploadNewPhoto = async (e, modelId) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('photo', file);
            try {
                setIsLoading(true);
                const resp = await axios.post(`${API_GENERATE_URL}/models/${modelId}/photos`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const newPhotoUrl = resp.data.photo_url; // Получаем URL новой фотографии
                setSelectedModel(prevModel => ({
                    ...prevModel,
                    photos: [...prevModel.photos, newPhotoUrl] // Обновляем массив фотографий в selectedModel
                }));
                const response = await axios.get(`${API_GENERATE_URL}/models/`);
                setModels(response.data.models);
            } catch (error) {
                console.error('Error uploading new photo:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };


    const handleImageClick = (index) => {
        if (index < selectedModel.photos.length) {
            setPhotoIndex(index);
            setLightboxOpen(true);
        }
    };

    const handleDeleteModel = async (modelId) => {
        try {
            setIsLoading(true);
            await axios.delete(`${API_GENERATE_URL}/models/${modelId}`);
            const response = await axios.get(`${API_GENERATE_URL}/models/`);
            setModels(response.data.models);
            setSelectedModel(null);
        } catch (error) {
            console.error('Error deleting model:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Container>
            <Header/>
            <Typography variant="h4" gutterBottom>
                Manage Models
            </Typography>
            <Grid container spacing={2} marginTop={2} alignItems="center">
                {models.map((model, index) => (
                    <Grid item key={index} style={{position: 'relative', width: '200px'}}>
                        <div style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => handleModelSelect(model)}>
                            <img
                                src={model.photos[0]}
                                style={{
                                    width: '100%',
                                    height: '200px',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    marginBottom: '8px',
                                    border: selectedModel && selectedModel.name === model.name ? '2px solid black' : 'none'
                                }}
                            />
                            <Typography variant="body2">{model.name}</Typography>
                        </div>
                        <IconButton
                            aria-label="delete"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '8px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            }}
                            onClick={() => handleDeleteModel(model.id)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                ))}
                <Grid item>
                    <div style={{textAlign: 'center', marginBottom: 15}}>
                        <IconButton color="primary" component="span" onClick={handleOpenModal}>
                            <AddCircleIcon style={{fontSize: 40}}/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>


            {selectedModel && (
                <div style={{marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '20px'}}>
                    <Typography variant="h6" gutterBottom>
                        {selectedModel.name}
                    </Typography>
                    <Grid container spacing={0} alignItems="center">
                        {selectedModel.photos.map((photo, index) => (
                            <Grid item xs={3} key={index} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{position: 'relative', cursor: 'pointer'}}
                                     onClick={() => handleImageClick(index)}>
                                    <img
                                        src={photo}
                                        alt={`model photo ${index}`}
                                        style={{
                                            width: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '8px'
                                        }}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        style={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                        onClick={() => handleDeleteModelPhoto(index)}
                                        disabled={selectedModel.photos.length === 1} // Отключить кнопку, если это последняя фотография
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </div>
                            </Grid>
                        ))}
                        <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
                            <input
                                accept="image/*"
                                style={{display: 'none'}}
                                id="upload-new-photo"
                                type="file"
                                onChange={(e) => handleUploadNewPhoto(e, selectedModel.id)}
                            />
                            <label htmlFor="upload-new-photo">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<PhotoCamera/>}
                                    disabled={isLoading}
                                >
                                    Upload New Photo
                                </Button>
                            </label>
                        </Grid>
                    </Grid>

                    <TextField
                        label="Hair Color"
                        fullWidth
                        value={hairColor}
                        onChange={(e) => setHairColor(e.target.value)}
                        style={{marginBottom: '20px', marginTop: '20px'}}
                    />
                    <TextField
                        label="Eye Color"
                        fullWidth
                        value={eyeColor}
                        onChange={(e) => setEyeColor(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Hairstyle"
                        fullWidth
                        value={hairstyle}
                        onChange={(e) => setHairstyle(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Age"
                        fullWidth
                        type="number"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Breast Size"
                        fullWidth
                        value={breastSize}
                        onChange={(e) => setBreastSize(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateModel}
                        disabled={isLoading}
                    >
                        Update Model
                    </Button>
                </div>
            )}

            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>Add New Model</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Model Name"
                        fullWidth
                        // value={newModelName}
                        onChange={handleNewModelNameChange}
                        style={{marginBottom: '20px', marginTop: '20px'}}
                    />
                    <TextField
                        label="Hair Color"
                        fullWidth
                        // value={hairColor}
                        onChange={(e) => setHairColor(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Eye Color"
                        fullWidth
                        // value={eyeColor}
                        onChange={(e) => setEyeColor(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Hairstyle"
                        fullWidth
                        // value={hairstyle}
                        onChange={(e) => setHairstyle(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Age"
                        fullWidth
                        type="number"
                        // value={age}
                        onChange={(e) => setAge(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <TextField
                        label="Breast Size"
                        fullWidth
                        // value={breastSize}
                        onChange={(e) => setBreastSize(e.target.value)}
                        style={{marginBottom: '20px'}}
                    />
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id="upload-new-model"
                        type="file"
                        multiple
                        onChange={handleModelFileChange}
                    />
                    <label htmlFor="upload-new-model">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<PhotoCamera/>}
                            disabled={isLoading}
                        >
                            Upload Model Photos
                        </Button>
                    </label>
                    <Grid container spacing={2} marginTop={2}>
                        {newModelFiles.map((file, index) => (
                            <Grid item xs={3} key={index}>
                                <div style={{position: 'relative'}}>
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`uploaded model ${index}`}
                                        style={{width: '100%'}}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        style={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                        onClick={() => handleDeleteModelFile(index)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    {isLoading && (
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <CircularProgress/>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary" disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button onClick={handleAddModel} color="primary" disabled={isLoading}>
                        Add Model
                    </Button>
                </DialogActions>
            </Dialog>
            {lightboxOpen && selectedModel && selectedModel.photos[photoIndex] && (
                <Lightbox
                    mainSrc={selectedModel.photos[photoIndex]}
                    nextSrc={selectedModel.photos[(photoIndex + 1) % selectedModel.photos.length]}
                    prevSrc={selectedModel.photos[(photoIndex + selectedModel.photos.length - 1) % selectedModel.photos.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + selectedModel.photos.length - 1) % selectedModel.photos.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % selectedModel.photos.length)
                    }
                />
            )}
        </Container>
    );

}

export default Models;
