import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import ChoosePhoto from "./pages/ChoosePhoto";
import GenerateContent from "./pages/GenerateContent";
import MysteryBox from "./pages/MysteryBox";
import History from "./pages/History";
import Models from "./pages/Models";
import Settings from "./pages/Settings";


function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/start" element={<ChoosePhoto/>}/>
                    <Route path="/generate/:sessionIdrUl" element={<GenerateContent />} />
                    <Route path="/mystery-box" element={<MysteryBox/>}/>
                    <Route path="/history" element={<History/>}/>
                    <Route path="/models" element={<Models/>}/>
                    <Route path="/settings" element={<Settings />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
