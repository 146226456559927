import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Switch,
    Button,
    Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../components/Header';

function Settings() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [switchStates, setSwitchStates] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);

    const API_GENERATE_URL = process.env.REACT_APP_API_URL;
    console.log('API_GENERATE_URL', API_GENERATE_URL)

    useEffect(() => {
        fetchFlows();
    }, [API_GENERATE_URL]);

    const fetchFlows = () => {
        axios.get(`${API_GENERATE_URL}/flows/`)
            .then(response => {
                const files = response.data.files;
                setData(files);
                setLoading(false);
                const savedFlow = localStorage.getItem('selectedFlow');
                const initialSwitchStates = files.reduce((acc, file) => {
                    acc[file] = file === savedFlow;
                    return acc;
                }, {});
                if (!savedFlow && files.length > 0) {
                    initialSwitchStates[files[0]] = true;
                    localStorage.setItem('selectedFlow', files[0]);
                } else if (savedFlow && !files.includes(savedFlow) && files.length > 0) {
                    initialSwitchStates[files[0]] = true;
                    localStorage.setItem('selectedFlow', files[0]);
                }
                setSwitchStates(initialSwitchStates);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    const handleSwitchChange = (fileName) => {
        setSwitchStates(prevState => {
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === fileName ? !prevState[key] : false;
                return acc;
            }, {});

            const isAnySwitchOn = Object.values(newState).some(value => value);

            if (newState[fileName]) {
                localStorage.setItem('selectedFlow', fileName);
            } else if (!isAnySwitchOn && data.length > 0) {
                newState[data[0]] = true;
                localStorage.setItem('selectedFlow', data[0]);
            } else {
                localStorage.removeItem('selectedFlow');
            }
            return newState;
        });
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post(`${API_GENERATE_URL}/upload_flow`, formData)
            .then(response => {
                fetchFlows();
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFileDelete = (fileName) => {
        axios.delete(`${API_GENERATE_URL}/delete_flow/${fileName}`)
            .then(response => {
                const savedFlow = localStorage.getItem('selectedFlow');
                fetchFlows(); // обновление данных после успешного удаления
                if (savedFlow === fileName && data.length > 0) {
                    localStorage.setItem('selectedFlow', data[0]);
                    setSwitchStates(prevState => ({
                        ...prevState,
                        [data[0]]: true
                    }));
                }
            })
            .catch(error => {
                setError(error);
            });
    };

    if (loading) {
        return <CircularProgress/>;
    }

    if (error) {
        return <Typography color="error">Ошибка загрузки данных: {error.message}</Typography>;
    }

    return (
        <Container>
            <Header/>
            <Typography variant="h4" gutterBottom>
                Settings
            </Typography>
            <Box sx={{textAlign: 'left', mb: 2}}>
                <Typography variant="body1" gutterBottom>
                    JSON загружать через кнопку Save (API Format)
                </Typography>
                <Typography variant="body2" gutterBottom>
                    #REF - реферальное фото (шаблон)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    #FACE - само лицо
                </Typography>
                <Typography variant="body1" gutterBottom>
                    #KSAMPLER - KSAMPLER для изменения seed
                </Typography>
                <Typography variant="body1" gutterBottom>
                    #STRING - для передачи lora (триггерные слова)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    #PROMPT - Передача промпта (общий промпт + зарактеристика модели)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    #LORA - Передача значения лоры
                </Typography>
            </Box>
            <List>
                {data.map((file, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={file}/>
                        <Switch
                            checked={switchStates[file]}
                            onChange={() => handleSwitchChange(file)}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon/>}
                            onClick={() => handleFileDelete(file)}
                        >
                            Delete
                        </Button>
                    </ListItem>
                ))}
            </List>
            <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleFileUpload}
                disabled={!selectedFile}
            >
                Upload Flow
            </Button>
        </Container>
    );
}

export default Settings;
